export const REC_ANALYTICS_EVENT_TYPES = {
  BALANCE_ALIGNMENT_PROCESS: {
    START: 'balance alignment, reconciliation start',
    UPDATE_RECONCILIATION_BA: 'balance alignment, reconciliation BA update',
    UPDATE_RECONCILIATION_BA_FAILED: 'balance alignment, reconciliation BA update failed',
    UPDATE_RECONCILIATION_REFLECTION: 'balance alignment, reconciliation reflection update',
    UPDATE_RECONCILIATION_REFLECTION_FAILED: 'balance alignment, reconciliation reflection update failed',
    VALIDATE_RECONCILIATION_DATA: 'balance alignment, reconciliation data validation',
    VALIDATE_RECONCILIATION_DATA_APPROVED: 'balance alignment, reconciliation data validation',
    VALIDATE_RECONCILIATION_DATA_REJECTED: 'balance alignment, reconciliation data rejected',
    VALIDATE_RECONCILIATION_APPROVED: 'balance alignment, reconciliation validation successful',
    VALIDATE_RECONCILIATION_REJECTED: 'balance alignment, reconciliation validation failed',
    UPDATE_RECONCILIATION_COMPLETE: 'balance alignment, reconciliation BA task before complete',
    UPDATE_RECONCILIATION_SKIP: 'balance alignment, reconciliation BA task before skip',
  },
};
