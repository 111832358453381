import { ref, onMounted, watch } from 'vue';
import { useTenancy } from '@/modules/auth';
import { experimentClient } from '../analytics-experiment';

export const FEATURE_FLAGS = {
  ALERTS: 'alerts',
};

/**
 * useFeatureFlags hook to fetch feature flags.
 * Each feature flag should include:
 *   - web: boolean
 *   - mobile: { ios: boolean, android: boolean }
 * The hook stores each feature flag in the featureFlags ref.
 * For each flag, if the 'on' variant is enabled and contains a payload, that payload is stored.
 * Example:
 *   'alerts' feature flag structure:
 *   {
 *     web: true,
 *     mobile: {
 *       ios: true,
 *       android: true
 *     },
 *    ...extra properties
 *   }
 *
 * This hook automatically fetches feature flags when a component mounts
 * and provides a reactive featureFlags object and an isFetching state.
 **/

export function useFeatureFlags() {
  const featureFlags = ref({});
  const isFetching = ref(false);
  const { currentTenant } = useTenancy();

  const fetchFeatureFlags = async () => {
    if (isFetching.value) {
      return;
    }
    isFetching.value = true;

    try {
      const res = await experimentClient?.fetch();
      if (res?.flags?.cache) {
        for (const key in res.flags.cache) {
          const enabledFlagVariant = res.flags.cache?.[key]?.variants?.on;

          const payload = enabledFlagVariant?.payload;
          const isEnabled =
            enabledFlagVariant?.value === 'on' &&
            payload?.web &&
            payload?.allowedBusinesses?.includes(currentTenant.value?.id);

          featureFlags.value = {
            ...featureFlags.value,
            [key]: payload ? { ...payload, isEnabled } : { isEnabled: false },
          };
        }
      }
    } catch (error) {
      console.error('Error fetching feature flags:', error);
    } finally {
      isFetching.value = false;
    }
  };

  watch(currentTenant, fetchFeatureFlags, { immediate: true });

  onMounted(fetchFeatureFlags);

  return {
    featureFlags,
    isFetching,
    fetchFeatureFlags,
  };
}
