import { Experiment } from '@amplitude/experiment-js-client';
import config from '@/config';

let experimentClient = null;

if (!(window.location.hostname.includes('pr-') && window.location.hostname.includes('.amplifyapp.com'))) {
  experimentClient = Experiment.initialize(config.VUE_APP_ANALYTICS_API_KEY);
  experimentClient
    .start()
    .then(() => {
      console.log('Experiment client started');
    })
    .catch((error) => {
      console.error('Error starting experiment client', error);
    });
}

export { experimentClient };
